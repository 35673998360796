.chamadaHome {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  .item {
    display: flex;
    flex: 1;
    align-items: center;
    position: relative;
    justify-content: center;
    padding-right: 20px;
    img {
      width: 40%;
      margin-right: 20px;
    }
    &::after {
      content: "";
      position: absolute;
      right: 10px;
      border-right: 1px solid var(--color-dark);
      height: 100px;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    span {
      font-size: 20px;
      line-height: 25px;
    }
  }
}

.bgHome {
  background-image: url(../../assets/img/bannerCaixas.png);
  height: 500px;
  background-size: cover !important;
}

.bannerHome {

  img {
    width: 100%;
    height: auto;
    &.mobile{
      display: none;
    }
  }
  @media only screen and (max-width: 600px) {
    img {
      &.desktop {
        display: none;
      }
      &.mobile{
        display: block;
      }
    }
  }
}
