@media print {
    .bilhete {
        -webkit-transform: scale(0.95);
        -moz-transform   : scale(0.95);
        transform        : scale(0.95);
    }
}

.pagebreak {
    page-break-after: auto;
}

body {
    line-height: 1 !important;
}

.block {
    background-color: #fff;
    opacity         : 0.1;
    position        : fixed;
    top             : 0;
    bottom          : 0;
    left            : 0;
    right           : 0;
}