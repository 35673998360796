footer {
  .footer-yellow {
    padding: 25px 0px;
    background: var(--color-primary);
    .boxFooter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      nav {
        display: flex;
        flex-wrap: wrap;
        min-width: 400px;
        ul {
          text-align: left;
          margin-right: 30px;
          flex: 1;
          > span {
            color: var(--color-secundary);
            font-weight: bold;
            font-size: 18px;
          }
          li {
            a {
              color: var(--color-secundary);
              display: block;
              margin-top: 15px;
            }
          }
        }
      }
      a.goSearch {
        background: var(--color-secundary) ;
        text-align: center;
        border: 0px;
        padding: 20px 20px;
        border-radius: 200px;
        font-weight: bold;
        font-size: 17px;
        max-width: 180px;
        color: #FFF;
        margin-left: -100px;
        text-transform: uppercase;
        text-decoration: none;
      }
      .sac {
        span {
          color: var(--color-secundary);
          display: block;
          margin: 10px 0px;
        }
        span.title {
          font-size: 18px;
        }
        span.cargaHoraria {
          font-size: 15px;
          margin-top: 0px;
        }
        .boxSac {
          display: flex;
          border: solid 1px var(--color-secundary);
          padding: 20px;
          figure {
            margin-right: 20px;
            img{
              height: 60px;
            }
          }
          span {
            display: block;
          }
        }
      }
    }
  }
}
.footer-white{
  width: 100%;
  height: 70px;
  padding: 25px 0px;
  background: #fff;
  .boxFooterWhite {
    display: flex;
    justify-content: space-between;
    align-items: center;
    nav {
      display: flex;
      flex-wrap: wrap;
      ul {
        text-align: left;
        margin-right: 30px;
        flex: 1;
        > span {
          color: var(--color-secundary);
          font-weight: bold;
          font-size: 18px;
        }
        li {
          a {
            color: var(--color-secundary);
            display: block;
            margin-top: 15px;
          }
        }
      }
    }
    .social {
      // margin-top: -35px;
      span {
        color: var(--color-secundary);
        display: block;
        margin: 10px 0px;
      }
      span.title {
        font-size: 16px;
        text-align: right;
        height: 10px;
      }
      span.subTitle {
        font-size: 12px;
        text-align: right;
        height: 10px;
      }
      .boxPayments {
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 400px;  
      }

      .box-icons{
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon{
          width: 30px;
          fill: var(--color-secundary);
          margin-left: 15px;
        }
      }
    }
  }

}
