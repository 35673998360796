.marcaCaoBus {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0px;
  padding: 20px;
  position: relative;
  .busMask {
    background: url(../../../../assets/img/onibus.svg) no-repeat center;
    background-size: 100% auto;
    width: 100%;
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px;
    padding-left: 100px;
    .fileiras {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: space-between;
      // background: red;
      width: auto;
      height: 100%;
      flex-wrap: wrap;
      div.cadeira {
        height: 17%;
        position: relative;
        cursor: pointer;
        span {
          position: absolute;
          top: 30%;
          width: 75%;
          text-align: center;
          color: #fff;
          font-size: 17px;
          font-weight: 600;
        }
        svg {
          // position: absolute;
          height: 100%;
        }
        .livre {
          path {
            fill: #a2c058;
          }
          path:nth-child(2) {
            fill: #91af4b;
          }
        }
        .mulher {
          path {
            fill: #e681b2;
          }
          path:nth-child(2) {
            fill: #ea69a4;
          }
        }
        .active {
          path {
            fill: #ffd85f;
          }
          path:nth-child(2) {
            fill: #91af4b;
          }
        }
        .active-mulher {
          path {
            fill: #ffd85f;
          }
          path:nth-child(2) {
            fill: #ea69a4;
          }
        }
        &.notFound{
          cursor: initial;
          path {
            fill: #fff;
          }
          path:nth-child(2) {
            fill: #fff;
          }
        }
      }
    }
  }
  .alinhamento{
    display: flex;
    margin-top: 20px;
    width: 100%;
    flex-wrap: wrap;
    .info {
      flex: 1;
      p {
        color: #707070;
      }
      span {
        color: #707070;
        font-size: 13px;
      }
      .assentos{
        margin-top: 10px;
        width: 50%;
        .item{
          display: flex;
          margin-top: 10px;
          justify-content: space-between;
          align-items: center;
          position: relative;
          padding-right: 50px;
          p{
            font-size: 14px;
            padding-left: 20px;
            &::before{
              content: "";
              position: absolute;
              width: 15px;
              height: 15px;
              top: 3px;
              border-radius: 4px;
              background: #a2c058;
              left: 0px;
            }
          }
          .value{
            button{
              margin-left: 10px;
              border: none;
              color: #ab1212;
              cursor: pointer;
            }
          }
          &.mulher{
            p {
              &::before{
                background: #e681b2;
              }

            }
          }
        }
      }
    }
    .legend {
      display: flex;
      margin: 20px 0px;
      width: 100%;
      p {
        margin-right: 25px;
        padding-left: 20px;
        position: relative;
        font-size: 14px;
        font-weight: 400;
        &::after {
          content: "";
          position: absolute;
          width: 14px;
          height: 14px;
          background: #000;
          left: 0px;
          top: 0px;
          border-radius: 3px;
        }
        &.livre {
          &::after {
            background: #a2c058;
          }
        }
        &.selecionado {
          &::after {
            background: var(--color-primary);
          }
        }
        &.ocupado {
          &::after {
            background: url(../../../../assets/img/assentoVazio.svg);
            background-size: 149%;
            background-position: -1px -1px;
            border: solid 1px #c6c6c6;
          }
          &.mulher {
            &::after {
              background: url(../../../../assets/img/assentoVazio.svg);
              background-size: 149%;
              background-position: -1px -1px;
              border: solid 1px #e681b2;
            }
          }
        }
        &.mulher {
          &::after {
            background: #e681b2;
          }
        }
      }
    }
    .action {
    justify-content: center;
    button {
      background: var(--color-primary);
      color: #06447c;
      border: none;
      padding: 9px 25px;
      border-radius: 7px;
      font-size: 15px;
      font-weight: bold;
    }
  }
  }
}
