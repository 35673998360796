.result-results-box {
  width: 100%;
  min-height: 120px;
  display: block;
  position: relative;
  margin: 1rem 0rem;
  -webkit-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  border: none;
  border-radius: 10px;
  box-shadow: #00000012 6px 6px 10px 6px;
  font-family: "Roboto", sans-serif !important;
  font-weight: normal;
  font-size: 14px;
  color: #707070;
  border-bottom: 1px solid #06447c;

  .service-classe span {
    line-height: 0.5;
  }
  .duration {
    line-height: 0.5;
    time {
      line-height: 1;
    }
  }

  .hour {
    color: #707070;
    font-size: 17px !important;
    .departure-time {
      font-weight: bolder;
    }
    .return-time {

    }
  }

  .footer {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    font-size: 13px;

    hr {
      border: -.9px groove;
    }
    .photo img {
      width: 90px;
      margin-left: -50px;
      margin-top: -8px;
    }
    .photo-one img {
      width: 90px;
      margin-left: -100px;
      margin-top: -8px;
    }
    .photo-one label {
      margin-left: -70px;
    }
    .photo label {
      margin-left: -20px;
    }
    .option {
      // margin-top: -5px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      img {
        height: 15px;
        margin-right: 7px;
        position: relative;
        top: 2px;
      }
    }
  }
  
  @media screen and (max-width: 1024px){
    display: flex;
  }
}
.pontronasLivres {
  display: flex;
  justify-content: flex-end;
  .item {
    margin-left: 20px;
  }
}

.result-results-box-align {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 20px;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 40px;
    margin: 20px 0px;
    // border-left: solid 1px #e4e4e4;
    &:last-child {
      border-left: none;
    }
  }
  .company {
    width: 15%;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  .hour {
    width: 7%;
  }
  .bus_stations {
    width: 22%;
    > div{
      width: 100%;
      text-align: center;
    }
  }
  .duration {
    width: 8%;
  }
  .service_classe {
    width: 15%;
  }
  .price {
    width: 10%;
    .price_value {
      color: #06447c;
      font-size: 18px;
      font-weight: bold;
  }
  .old-price {
      font-size: 12px;
      text-decoration: line-through;
  }
  }
  .selecionar {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      background-color: var(--color-primary);
      font-weight: bold;
      color: #06447c;
      border-radius: 10px;
      &.btn-cancell {
        background: #fff;
      }
    }
  }
}
