.ReactModalPortal{
  .ReactModal__Overlay{
    z-index: 3 !important;
  }
}
.grid-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  .input {
    width: 60%;
    .form-box {
      border: solid 1px #d6d3d3;
      border-radius: 5px;
      min-width: 250px;
      margin-right: 20px;
    }
    .form-box-input {
      border: solid 1px #d6d3d3;
      border-radius: 5px;
      min-width: 250px;
      margin-right: 20px;
    }
    .form-box-service {
      border: solid 1px #d6d3d3;
      border-radius: 5px;
      min-width: 250px;
      margin-right: 20px;
      padding: 12px;
    }
    .form-box-payment {
      border: solid 1px #d6d3d3;
      border-radius: 5px;
      min-width: 250px;
      margin-right: 20px;
    }
    .form-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
    }
    .flex-start {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;
    }
  }
  .resume {
    width: 40%;
    .box-destiny {
      border: solid 1px #d6d3d3;
      padding: 12px;
      min-width: 250px;
      margin-bottom: 20px;
    }
    .box-discont {
      margin-top: 25px;
      padding: 20px 0px 0px 0px; /* top, right, bottom, left*/
      border: solid 1px #d6d3d3;
      min-width: 250px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      #hr-discount {
        width: 100%;
        border-bottom: 1px solid #d6d3d3;
        margin: 12px 12px 0px 12px;
      }
    }
    .box-total {
      margin-top: 1px;
      padding: 20px 12px 20px 12px; /* top, right, bottom, left*/
      border: solid 1px #d6d3d3;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      background: var(--color-primary);
      color: var(--color-secundary);
      .total {
        font-weight: 400;
        font-size: 22px;
        text-align: right;
      }
      .info {
        font-weight: 400;
        font-size: 22px;
        text-align: left;
      }
    }
    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
    }
    .flex-start {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;
    }
  }
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }

  #hr {
    width: 100%;
    border-bottom: 1px solid #d6d3d3;
  }

  .space-top {
    margin-top: 20px;
  }
  .space-left {
    margin-left: 12px;
  }

  .space-bottom {
    margin-top: 20px;
  }

  .button {
    background-color: var(--color-primary);
    border-radius: 7px;
    color: var(--color-secundary);
    padding: 10px 30px;
    width: 100%;
    border: none;
    &:hover {
      position: relative;
      left: -1px;
      top: -1px;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 25px;
        margin-right: 10px;
      }
    }
    &:disabled {
      opacity: 0.5;
    }
  }

  .lg {
    height: 45px;
    font-weight: 400;
    font-size: 18px;
  }

  .label {
    color: #999797;
    font-weight: 300;
    font-size: 15px;
  }

  .title {
    font-weight: 400;
    font-size: 16px;
    color: var(--color-secundary);
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .detail {
    margin-bottom: 10px;
    color: #999797;
    font-weight: 400;
    font-size: 15px;
  }

  .text {
    margin-bottom: 10px;
    color: #999797;
    font-size: 15px;
    &.button-verDatelhes {
      cursor: pointer;
    }
  }

  .showDetalhes {
    margin-top: 30px;
    h2 {
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}

.textNumberCard {
  display: flex;
  width: 100%;
  align-items: center;
  img {
    height: 20px;
    margin-left: 10px;
  }
}
.section-expired {
  margin-top: 20px;
  color: red;
}
