.grid-div-logged{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 40px;
    .menu-logged{
        width: 27%;
        border: solid 1px #d6d3d3;
        height: 670px;
        .link-menu{
            margin-top: 40px;
            color: #ccc;
            font-size: 24px;
            padding: 18px;
            font-weight: 400;
        }
        .active{
            p{
              color: var(--color-secundary);
            }
        }
        .hr-center {
            width: 90%;
            border-bottom: 1px solid #d6d3d3;
            margin-left: 18px;
        }
    }
}
