@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes center-animate {
  0% {
    transform: scaleX(120);
  }

  100% {
    transform: scaleX(0);
  }
}

.result-dates {
  text-align: center;

  ul {
    animation : slideInFromLeft 0.2s ease-in-out backwards alternate;
    transition: width 1s ease-in-out;

    li {
      height          : 50px;
      border          : 1px solid var(--color-primary);
      margin-inline   : 10px;
      margin-block    : 5px;
      border-radius   : 6px;
      background-color: var(--color-white);
      display         : flex;
      flex-direction  : column;
      align-items     : center;
    }
  }
}

.results {
  display    : block;
  flex       : 1;
  font-family: "Roboto", sans-serif !important;
  font-weight: normal;

  .search-results {
    width             : 100%;
    display           : block;
    position          : relative;
    margin            : 0;
    -webkit-transition: opacity 0.5s ease;
    -o-transition     : opacity 0.5s ease;
    transition        : opacity 0.5s ease;
    border-radius: 10px;
    box-shadow: #00000012 6px 6px 10px 6px;
    border: none;
  }

  .tabs-fixed-width.date-tabs,
  .tabs.tabs-fixed-width {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .date-tabs .tabs.tabs-fixed-width .date-tab,
  .tabs-fixed-width.date-tabs .date-tab,
  .tabs-fixed-width.date-tabs .tab,
  .tabs.tabs-fixed-width .date-tabs .date-tab,
  .tabs.tabs-fixed-width .tab {
    -webkit-box-flex : 1;
    -ms-flex-positive: 1;
    flex-grow        : 1;
  }

  .date-tab a {
    color: #707070;
  }

  .date-tabs 
  .date-tab, 
  .date-tabs .tab, 
  .tabs .tab{
    display: inline-block;
    text-align: center;
    line-height: 20px;
    min-width: 80px;
    width: 15%;
    padding: 10px 0px;
    margin: 0;
    margin-right: -2px !important;
    text-transform: none;
    box-sizing: border-box;
    border-top: none!important;
    border-right: none!important;
    position: relative;
  }

  .date-tabs .date-tab {
    text-transform    : capitalize;
    -webkit-transition: color 0.28s ease;
    -o-transition     : color 0.28s ease;
    transition        : color 0.28s ease;
    position          : relative;
  }

  .date-tabs .date-tab,
  .date-tabs .tab,
  .tabs .tab {
    display           : inline-block;
    text-align        : center;
    line-height       : 20px;
    min-width         : 80px;
    width             : 15%;
    padding           : 20px 0px 10px 0px;
    margin            : 0;
    margin-right      : -2px !important;
    text-transform    : none;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    border-top        : none;
    border-right      : none;
    position          : relative;
  }

  .date-tabs {
    cursor: pointer;

    svg {
      width        : 20px;
      height       : 20px;
      margin-bottom: 10px !important;
    }

    .price {
      width     : 100%;
      display   : block;
      font-size : 14px;
      margin-top: 4px;
    }
  }

  .activated.date-tab a {
    color: #06447c !important;

    .price {
      color: #06447c !important;
    }
  }

  .activated {
    border-top : 5px solid #06447c !important;
    font-weight: bold;

    .price {
      color: #06447c !important;
    }
  }

  .sort-results {
    overflow: hidden;
    background: #ffcc3e;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .sort-results ul {
    display        : flex;
    justify-content: space-between;
    flex-wrap      : wrap;
  }

  .sort-results li {
    padding: 18px 0px 10px;
    margin: 0rem 2rem;
    text-align: center;
    width: auto !important;
    color: var(--color-secundary);

    // border-left: solid 1px #efc343;
    &:last-child {
      border-left: none;
    }

    &.company {
      width: 15%;
    }

    &.hour {
      width: 7%;
    }

    &.bus_stations {
      width: 22%;
    }

    &.duration {
      width: 8%;
    }

    &.service_class {
      width: 15%;
    }

    &.price {
      width: 10%;
    }
  }

  .sort-results ul li {
    margin-left: 5px;
  }

  .company {
    width       : 100px;
    padding-left: 7px;
  }
}

button.menu-responsive {
  display: block;
  background: transparent;
  cursor: pointer;
  height: 25px;
  width: 30px;
  border: none;
  border-bottom: solid 5px var(--color-secundary);
  position: relative;
  &::after,
  &::before {
    content: "";
    position: absolute;
    background: transparent;
    width: 30px;
    border: none;
    border-bottom: solid 5px var(--color-secundary);
    top: 0px;
    left: 0px;
  }
  &::before {
    top: 10px;
  }
}

.actionsResponsive {
  display: flex;
  justify-content: space-evenly;
  button {
    background-color: var(--color-secundary);
    border-radius: 20px;
    color: #fff;
    padding: 10px 30px;
    border: none;
  }
}