.naoEncontrada{
  text-align: center;
  padding: 200px 0px;
  button {
    background-color: var(--color-secundary);
    border-radius: 20px;
    color: #fff;
    padding: 10px 30px;
    border: none;
    margin-top: 50px;
  }
}