*{
  box-sizing: border-box;
}

.alert{
  margin: 20px 0px;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  background: #ffaaaa;
  border: solid 1px #ab1212;
  color: #ab1212;
  &.error{
    background: #ffaaaa;
    border: solid 1px #ab1212;
    color: #ab1212;
  }
  &.success{
    background: #b3ffcb;
    border: solid 1px #1d5224;
    color: #1d5224;
  }
}

form {
  max-width: 500px;
  margin: 0 auto;
  border-radius: 8px;
  border: solid 1px #cccccc;
  padding: 20px;
  color: #525659;
  .box-form {
    label {
      display: block;
      color: #525659;
      font-weight: normal;
      font-size: 20px;
      margin: 10px 0px;
    }
    input {
      display: block;
      width: 100%;
      padding: 12px;
      font-size: 20px;
      color: #525659;
      border-radius: 8px;
      border: solid 1px #cccccc;
      margin-top: 5px;
      margin-bottom: 25px;
    }

    small{
      position: absolute;
      margin-top: -22px;
      margin-bottom: 25px;
      color: var(--color-alert);
    }

    textarea {
      width: 100%;
      resize: none;
      padding: 12px;
      font-size: 20px;
      color: #525659;
      border-radius: 8px;
      border: solid 1px #cccccc;
      margin-top: 5px;
      margin-bottom: 25px;
    }
  
    .duoLabel{
     display: flex; 
     justify-content: space-between;
     align-items: center;
    }
  }
  .btnForm{
    button{
      background: var(--color-primary);
      width: 100%;
      margin-bottom: 20px;
      border: none;
      color: var(--color-secundary);
      font-weight: bold;
      font-size: 20px;
      border-radius: 7px;
      span{
        padding: 15px;
        display: block;
      }
      img.loadingButton{
        height: 40px;
      }
      &:disabled{
        opacity: 0.5;
      }
    }
  }
  .actions{
    text-align: center;

  }
}
