.formPerfil {
    max-width: 100%;

    button {
        svg {
            path {
                fill: red;
            }
        }
    }
}

.container-info {
    width: 70%;

    .box-title-myAccount {
        height         : 90px;
        justify-content: flex-start;
        align-items    : flex-start;

        .title-logged {
            color      : var(--color-secundary);
            font-size  : 37px;
            font-weight: 400;
            text-align : left;
            margin-top : 60px;
        }
    }

    .box-container-form {
        border         : solid 1px #d6d3d3;
        height         : 500px;
        margin-top     : -40px;
        display        : flex;
        flex-direction : column;
        justify-content: center;
        align-items    : center;
        width          : 100%;

        .formdiv {
            width        : 100%;
            padding-left : 30px;
            padding-right: 30px;


        }

        .formdiv:last-child {
            padding-top: 30px;
        }

        .textForm {
            margin-bottom: 10px;
            font-size    : 18px;
            font-weight  : 400;
        }

        .myAcountInput {
            padding: 20px;
        }

        .space-top {
            margin-top: 20px;
        }

        .btnForm {
            display        : flex;
            flex-direction : row;
            justify-content: center;
            align-items    : center;

            .btnFormSave {
                background-color: var(--color-primary);
                color           : var(--color-secundary);
                height          : 60px;
                width           : 200px;
                border          : solid 1px #d6d3d3;
                border-radius   : 5px;
                font-size       : 18px;
                font-weight     : 400;
            }

        }

    }




}

.update {
    width          : 100% !important;
    flex-direction : row;
    justify-content: center;
    margin-top     : 40px;
    margin-bottom  : 40px;
}