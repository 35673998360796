body {
  // font-family: "Rubik", sans-serif;
  font-family        : Helvetica, Arial, sans-serif;
  background-size    : cover;
  background-position: center center;
  height             : 100vh;
  width              : 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin        : 0;
  padding       : 0;
  border        : 0;
  font-size     : 100%;
  vertical-align: initial;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing : 0;
}

*:focus {
  outline: none;
}

h2 {
  color        : var(--color-secundary);
  font-size    : 30px;
  margin-bottom: 20px;
}

.loading {
  padding   : 20px;
  text-align: center;
}

.loadingHeight {
  padding: 200px 0px;
}

.sction_dtls {
  background        : #FFC512;
  width             : 9rem;
  padding           : 0.75rem;
  display           : flex;
  align-items       : center;
  justify-content   : center;
  border-radius     : 10px;
  -webkit-box-shadow: 0px 0px 41px 0px #085BA6;
  box-shadow        : 0px 0px 20px 0px #06447C;
  font-size         : 9pt;
  margin-bottom     : 20pt;
}