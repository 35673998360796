
.title-div{
    width: 100%;
    display: flex;
    justify-content:center;
    flex-direction: column;
    flex-wrap: wrap;
    .title-principal{
        color: var(--color-secundary); 
        font-size: 25px;
        text-align: center; 
        margin-bottom:20px;
        margin-top: 70px; 
        font-weight: 400;  
    }
    .title-secundario{
        color: var(--color-secundary); 
        font-size: 25px;
        text-align: center; 
        margin-bottom:20px;
        margin-top: 0px;   
    } 
    .info{
        color: #999797;
        font-weight: 300;
        font-size: 15px;   
        text-align: center; 
        margin-bottom: 10px;  
    }
}

.div-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-self:flex-start;

    .container{
        width: 40%; 
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-self:flex-start;

        .text{
            margin-bottom: 10px;
            color: #999797;
            font-size: 15px;
        }
        
        .title{
            text-align: left;
            font-weight: 400;
            font-size: 16px;
            color: var(--color-secundary);
            margin-bottom: -10px;
            margin-top: 20px;
        }
        
        .box-resume{
            width: 100%;
            border: solid 1px #d6d3d3;
            min-width: 250px;
            margin-right: 20px;  
            display: flex;
            flex-wrap:wrap;
            flex-direction: row;
            margin-bottom: 30px;
            padding: 25px 12px 25px 12px; /* top, right, bottom, left*/
    
            .elements{
                display: flex;
                justify-content: space-between; 
            }
            p{
                width: 100%;
            }

            .space-top{
                margin-top: 20px;
            }
        }
        .box-total{
            width: 100%;
  
            border: solid 1px #d6d3d3;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            background: #fff;
            color: var(--color-secundary); 
            padding: 25px 12px 25px 12px; /* top, right, bottom, left*/
            .total{
                font-weight: 500;
                font-size: 20px;
            }    
        }
    }    
       
    .map {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-self:flex-start;

        width: 60%;

        min-width: 250px;

        img{
            background-repeat:no-repeat;
            background-size:cover; 
            width: 100%;
            max-height: 312px;
        }
        .title{
            text-align: left;
            font-weight: 400;
            font-size: 16px;
            color: var(--color-secundary);
            margin-bottom: -10px;
            margin-top: 20px;
        }
        .containerMap{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-self:center;
            border: solid 1px #d6d3d3;

            .divContainerImg{
                width: 31%;
                height: 357px;

                .containerTitleMap{
                    display: flex;
                    justify-content: center;
                    align-self:center;   

                    .tilteMap{
                        font-size: 14px;
                        
                        padding-top: 10px;
                        padding-bottom: 10px;
                        color: var(--color-secundary);
                    }
                }
            }
        }
    }
    
    .download {
        margin-top: 60px;
        width: 100%;
        padding: 12px;
        min-width: 250px;
        .text-download{
            color: #999797;
            font-weight: 400;
            font-size: 18px; 
            margin-top: 25px;
        }
        .small{
            margin-left: 20px;
            font-size: 15px;
            background-color: var(--color-primary);
            border-radius: 7px;
            color: var(--color-secundary);
            padding: 5px;
            width: 60px;
            border: none;
            &:hover{
                position: relative;
                left: -1px;
                top: -1px;
                box-shadow: 2px 2px 2px rgba(0,0,0,0.3);
            }
        }
    }

    .buttons{
        margin-top: 60px;
        width: 100%; 
        display: flex;
        flex-wrap:wrap;
        justify-content: space-evenly;
        flex-direction: row;
        .large{
            background-color: var(--color-secundary);
            border-radius: 7px;
            color: #fff;
            padding: 10px 30px;
            width: 40%;
            height: 55px;
            font-weight: 300;
            font-size: 18px;
            border: none;
            &:hover{
                position: relative;
                left: -1px;
                top: -1px;
                box-shadow: 2px 2px 2px rgba(0,0,0,0.3);
            }
        }   
    }

}
.important{
    color: crimson !important;
    text-transform: uppercase !important;
    font-weight: 900;
}




