.filters ul li a input{
  margin-left: -15px;
  margin-right: 0.3rem;
}
.pagePassagens {
  header {
    .align-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .contador {
        display: flex;
        align-items: center;
        p,
        span {
          margin: 0px;
          padding: 0px;
          font-size: 14px;
          text-align: right;
          margin: 5px;
        }
        span {
          color: var(--color-secundary);
          font-weight: bold;
        }
      }
    }
    .lineHeight {
      border-bottom: none;
      b, strong {
        font-weight: bolder;
        color: #06447c;
    }
    }
    h1 {
      font-size: 20px;
      padding: 20px 0px;
      font-weight: 400;
      color: #707070;
    }
    span {
      font-size: 16px;
      display: block;
      padding: 20px 0px;
      color: #707070;
    }
  }
  a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .search-results {
    display: flex;
    margin: 40px 0px;
    flex-wrap: wrap;
    .noResults {
      width: 100%;
    }
    .actionsResponsive {
      display: none;
    }
  }
  .steps {
    display: flex;
    .item {
      border-top: solid 1px #c6c6c6;
      border-bottom: solid 1px #c6c6c6;
      flex: 1;
      font-weight: 400;
      position: relative;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after {
        content: "";
        position: absolute;
        top: -1px;
        right: -32px;
        height: 100%;
        border: solid 17px;
        border-color: transparent transparent transparent #fff;
        z-index: 2;
      }
      &::before {
        content: "";
        position: absolute;
        top: -1px;
        right: -33px;
        height: 100%;
        border: solid 17px;
        border-color: transparent transparent transparent #c6c6c6;
        z-index: 2;
      }
      &:first-child {
        border-left: solid 1px #c6c6c6;
      }
      &:last-child {
        border-right: solid 1px #c6c6c6;
        &::after,
        &::before {
          display: none;
        }
      }
      &.active {
        border-top: solid 1px var(--color-secundary);
        border-bottom: solid 1px var(--color-secundary);
        border-bottom: solid 1px var(--color-secundary);
        border-left: solid 1px var(--color-secundary);
        color: #fff;
        background: var(--color-secundary);
        &::after {
          border-color: transparent transparent transparent
            var(--color-secundary);
        }
        &:before {
          border-color: transparent transparent transparent
            var(--color-secundary);
        }
      }
      &.preview {
        &:before {
          border-color: transparent transparent transparent
            var(--color-secundary);
        }
      }
    }
  }
}

.modal-session {
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  .content {
    background: #fff;
    padding: 60px;
    border-radius: 10px;
    p {
      font-weight: bold;
      font-size: 20px;
      color: var(--color-secundary);
    }
    span{
      display: block;
      text-align: center;
      width: 100%;
      margin-top: 40px;
    }
    a {
      background-color: var(--color-secundary);
      border-radius: 20px;
      color: #fff;
      padding: 10px 30px;
      border: none;
      margin-top: 40px;
    }
  }
}

footer .footer-yellow .boxFooter nav ul{
  text-align: left;
  margin-right: 30px;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
}

@media screen and (width: 1024px){
  .pagePassagens {
    header {
      .align-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .contador {
          display: flex;
          align-items: center;
          p,
          span {
            margin: 0px;
            padding: 0px;
            font-size: 14px;
            text-align: right;
            margin: 5px;
          }
          span {
            color: var(--color-secundary);
            font-weight: bold;
          }
        }
      }
      .lineHeight {
        border-bottom: none;
      }
      h1 {
        font-size: 20px;
        padding: 20px 0px;
        font-weight: 400;
        color: #707070;
      }
      span {
        font-size: 16px;
        display: block;
        padding: 20px 0px;
        color: #707070;
      }
    }
    a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
      color: inherit;
      text-decoration: none;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
    }
    .search-results {
      display: flex;
      margin: 40px 0px;
      flex-wrap: wrap;
      .noResults {
        width: 100%;
      }
      .actionsResponsive {
        display: none;
      }
    }
    .steps {
      display: flex;
      .item {
        border-top: solid 1px #c6c6c6;
        border-bottom: solid 1px #c6c6c6;
        flex: 1;
        font-weight: 400;
        position: relative;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        &::after {
          content: "";
          position: absolute;
          top: -1px;
          right: -32px;
          height: 100%;
          border: solid 17px;
          border-color: transparent transparent transparent #fff;
          z-index: 2;
        }
        &::before {
          content: "";
          position: absolute;
          top: -1px;
          right: -33px;
          height: 100%;
          border: solid 17px;
          border-color: transparent transparent transparent #c6c6c6;
          z-index: 2;
        }
        &:first-child {
          border-left: solid 1px #c6c6c6;
        }
        &:last-child {
          border-right: solid 1px #c6c6c6;
          &::after,
          &::before {
            display: none;
          }
        }
        &.active {
          border-top: solid 1px var(--color-secundary);
          border-bottom: solid 1px var(--color-secundary);
          border-bottom: solid 1px var(--color-secundary);
          border-left: solid 1px var(--color-secundary);
          color: #fff;
          background: var(--color-secundary);
          &::after {
            border-color: transparent transparent transparent
              var(--color-secundary);
          }
          &:before {
            border-color: transparent transparent transparent
              var(--color-secundary);
          }
        }
        &.preview {
          &:before {
            border-color: transparent transparent transparent
              var(--color-secundary);
          }
        }
      }
    }
  }
}