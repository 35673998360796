.results {
  display: block;
  flex: 1;
  font-family: "Roboto", sans-serif !important;
  font-weight: normal;

  .search-results {
    width: 100%;
    display: block;
    position: relative;
    margin: 0;
    -webkit-transition: opacity 0.5s ease;
    -o-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
    border: none;
    border-radius: 10px;
    box-shadow: #00000012 6px 6px 10px 6px;
  }

  .tabs-fixed-width.date-tabs,
  .tabs.tabs-fixed-width {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .date-tabs .tabs.tabs-fixed-width .date-tab,
  .tabs-fixed-width.date-tabs .date-tab,
  .tabs-fixed-width.date-tabs .tab,
  .tabs.tabs-fixed-width .date-tabs .date-tab,
  .tabs.tabs-fixed-width .tab {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .date-tab a {
    color: #707070;
  }
  .date-tabs .date-tab {
    text-transform: capitalize;
    -webkit-transition: color 0.28s ease;
    -o-transition: color 0.28s ease;
    transition: color 0.28s ease;
    position: relative;
  }

  .date-tabs .date-tab,
  .date-tabs .tab,
  .tabs .tab {
    display: inline-block;
    text-align: center;
    line-height: 20px;
    min-width: 80px;
    width: 15%;
    padding: 10px 0px;
    margin: 0;
    margin-right: -2px !important;
    text-transform: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-top: 1px solid #e3e3e3;
    border-right: 2px solid #e3e3e3;
    position: relative;
  }

  .date-tabs{
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      margin-bottom: 10px !important;
    }
    .price{
      width: 100%;
      display: block;
      font-size: 14px;
      margin-top: 4px;
    }
  }

  .activated.date-tab a {
    color: #06447c !important;
    .price{
      color: #06447c !important;
    }
  }
  .activated {
    border-top: 5px solid #06447c !important;
    font-weight: bold;
    .price{
      color: #06447c !important;
    }
  }

  .sort-results {
    overflow: hidden;
    background: #ffcc3e;
    border-radius: 5px;
  }
  .sort-results ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .sort-results li {
    padding: 8px 0px;
    text-align: center;
    width: 15%;
    color: var(--color-secundary);
    // border-left: solid 1px #efc343;
    &:last-child{
      border-left: none;
    }
    &.company{
      width: 15%;
    }
    &.hour{
      width: 7%;
    }
    &.bus_stations{
      width: 22%;
    }
    &.duration{
      width: 8%;
    }
    &.service_class{
      width: 15%;
    }
    &.price{
      width: 10%;
    }
  }

  .sort-results ul li {
    margin-left: 24px;
  }
  .company {
    width: 100px;
    padding-left: 7px;
  }
}

.result-results-box-align .price {
  width: 10%;
  margin: 0% !important;
}
