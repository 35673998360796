.filters{
  border-radius: 10px;
  box-shadow: #00000012 6px 6px 10px 6px;
  display: block;
  padding: 25px;
  padding-bottom: 0px;
  padding-top: 20px;
  min-width: 220px;
  height: 30%;
  max-height: 100%;
  margin-right: 20px;
  font-family: "Roboto", sans-serif !important;
  font-weight: normal;
  color: #707070;
  text-transform: capitalize;
  ul li a {
    font-size: 14px;
    input{
      margin-left: -15px;
    }
  }
  label{
    width: 100%;
    height: 10px;
    display: block;
    float: right;
    margin-top: 3px;
    font-size: 12px;
    font-family: "Roboto",sans-serif !important;
    font-weight: normal;
    margin-bottom: 0rem;
  }
}

.goFilters{
  border: solid 1px #FFFF;
  border-radius: 10px;
  box-shadow: #00000012 6px 6px 10px 6px;
  display: block;
  padding: 12px;
  height: 30%;
  max-height: 100%;
  margin-bottom: 50px;
  font-family: "Roboto",sans-serif !important;
  font-weight: normal;
  ul li a {
    font-size: 12px;
  }
  label{
    font-size: 12px;
    font-family: "Roboto",sans-serif !important;
    font-weight: normal;
  }
  button {
    background-color: var(--color-primary);
    font-weight: bold;
    color: #06447c;
    border-radius: 10px;
  }
  input[type=radio]:checked:after {
    background-color: #ffcc3e;
  }
}

.nav{
  display: flex;
  flex-direction: column;
  padding: 0rem;
}
.nav-content{
  display: flex;
  flex-direction: row;
}
.nav__filters{
  display: flex!important;
  flex-direction: row;
}
.nav__classes{
  display: flex;
  flex-direction: row;
}
.nav>li>a:focus, .nav>li>a:hover {
  text-decoration: none;
  background-color: #fff!important;
  cursor: pointer;
}
.nav>li {
  position: relative;
  display: block;
  cursor: pointer!important;
}
