.destinos{
  margin: 40px 0px;
  h2{
    text-align: center;
    font-size: 22px;
    font-weight: bold;
  }
  h3{
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-size: 15px;
    color: #fff;
  }
  h3 span{
    font-size: 18px;
    font-weight: bold;
  }

  .box-destinos{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;
    .item{
      padding-right: 10px;
      margin-bottom: 10px;
      width: 25%;
      min-height: 250px;
      max-height: 285px !important;
      position: relative;
      background-size: cover !important;
      img {
        width: 100%;
        min-height: 285px;
        max-height: 285px !important;
      }
      h2{
        position: absolute;
        left: 15px;
        top: 15px;
        text-align: left;
        font-size: 18px;
        color: #fff;
        font-weight: bold;
      }
      h3{
        position: absolute;
        bottom: 15px;
        right: 15px;
        font-size: 15px;
        color: #fff;
        span{
          font-size: 18px;
          font-weight: bold;
        }
      }
      &:last-child, &:nth-child(3) {
        padding-right: 0px;
      }
      &:nth-child(6n), &:first-child {
          width: 50%;
          max-height: 285px !important;
          img {
            width: 100%;
            max-height: 285px !important;
          }
      }
    }
  }
}

.rgba-red-light, .rgba-red-light:after{
  background-color: rgba(6 , 68 , 124, 0.5);
}

.btn-outline-info{
  border: solid 2px var(--color-secundary) !important;
}
.btn-outline-info:hover{
  border: solid 2px var(--color-secundary) !important;
}
