.passagem_mobile {
    // /* CARROSSEL */

    // .date-carousel {
    //     box-sizing: border-box;
    //     display: flex;
    //     height: 0.5in;
    //     margin: auto;
    //     margin-bottom: 1rem;
    //     position: relative;
    //     width:2in;
    // }

    // .date-carousel-next,
    // .date-carousel-prev,
    // .date-carousel-input {
    //     box-sizing: border-box;
    //     position:relative;
    //     border: none;
    // }

    // .date-carousel-next,
    // .date-carousel-prev {
    //     background-color: transparent;
    //     color: var(--color-secondary);
    //     cursor: pointer;
    //     font-size: larger;
    //     font-weight: bold;
    //     width: 0.5in;
    // }

    // .date-carousel-input {
    //     color: var(--color-secondary);
    //     background-color: transparent;
    //     text-align: center;
    //     align-items:center;
    //     width: 4in;
    //     font-size: 1rem;
    // }

    // /* Disable Native UI */

    // .date-carousel-input::-webkit-inner-spin-button,
    // .date-carousel-input::-webkit-input-placeholder{
    //     display: none;
    //     -webkit-appearance: none;
    // }

    /* HEADER */

    .page header {
        margin-top : 10%;
        align-items: center;
        text-align : center;
    }

    .page #alt {
        width           : 45%;
        text-align      : center;
        align-items     : center;
        border          : none;
        font-weight     : bold;
        color           : var(--color-secondary);
        background-color: var(--color-primary);
        text-align      : center;
        align-items     : center;

        border       : none;
        border-radius: 6px;
        cursor       : pointer;
    }

    .page #alt p {
        margin-top   : 1rem;
        margin-bottom: 1rem;
    }

    .page .informacoes {
        display: none;
    }

    /* MAIN */

    .page .info {
        margin-top  : 7%;
        margin-right: 0%;
        align-items : center;
    }

    .page .txt__info {
        margin-left: 4%;
        text-align : left;
    }

    .page .info span {
        color : #595959;
        cursor: text;
    }

    .page .info p {
        color      : #595959;
        font-weight: bold;
        cursor     : text;
        text-align : left;
    }


    /* CARD */

    .page .about__card {
        display: none;
    }

    .page .main__card {
        background   : #ffffff;
        margin-bottom: 10px;
        padding-top  : 14px;
        width        : 95%;
        margin       : 0 auto 15px;
        border-radius: 5px;
        box-shadow   : #00000012 6px 6px 10px 6px;
    }

    .page .main__card img {
        width       : 65px;
        margin-top  : 1rem;
        margin-right: 2rem;
    }

    .page .main__card span {
        color      : #595959;
        width      : 15px;
        font-size  : 12px;
        margin-left: 4%;
        cursor     : text;
    }

    .page .main__card p {
        font-size    : 12px;
        margin-left  : 4%;
        margin-bottom: 0%;
        cursor       : text;
    }

    .page .main__card hr {
        width: 92%;
        color: #595959;

    }

    .page .up {
        display        : flex;
        justify-content: center;
    }

    .page .up i {
        margin-left : 1.6rem;
        margin-right: 1.6rem;
        margin-top  : .3rem;

    }

    .page .embarque {
        margin-top: .8rem;
        text-align: center;
    }

    .page .embarque p {
        font-weight: bold;
        font-size  : 25px;
        color      : #595959;
        margin-top : 0%;
        cursor     : text;
    }

    .page .chegada {
        margin-top: .8rem;
        text-align: center
    }

    .page .chegada p {
        font-weight: bold;
        font-size  : 25px;
        color      : #595959;
        margin-top : 0%;
        cursor     : text;
    }

    .page .down {
        padding-bottom : 1rem;
        display        : flex;
        align-items    : center;
        justify-content: space-around;
        flex-direction : row;
        padding-left   : 1rem;
        padding-right  : 1.8rem;
    }

    .page .time {
        width       : 6.3rem;
        margin-left : .5rem;
        margin-right: .5rem;
        text-align  : center;
    }

    .page .time p {
        font-weight: 500;
        cursor     : text;
    }

    .page .bus__type span {
        font-size: 10px;
        cursor   : text;
    }

    .page .bus__type p {
        width      : 6rem;
        font-weight: 500;
        cursor     : text;
        margin-top : 0px;
    }

    .page .price {
        margin-left: 0rem;
        align-items: center;
        text-align : center
    }

    .page .price p {
        font-weight: bold;
        font-size  : 20px;
        color      : #595959;
        cursor     : text;
        margin-top : 0px;
    }

    .page .rest {
        background-color: var(--color-primary);
        border-radius   : 6px;
        display         : flex;
        align-items     : center;
        text-align      : center;
        margin-left     : .6rem;
        margin-top      : .4rem;
    }

    .page .rest i {
        margin-left: .5rem;
    }

    .page .rest p {
        font-size   : 10px;
        font-weight : 500;
        margin      : 4%;
        margin-right: 10px;
        cursor      : text;
    }

    .page #select__btn {
        width           : 90%;
        text-align      : center;
        align-items     : center;
        border          : none;
        font-weight     : bold;
        color           : var(--color-secondary);
        background-color: var(--color-white);

        text-align   : center;
        align-items  : center;
        padding-left : 10%;
        padding-right: 10%;
        border       : 1px solid var(--color-primary);
        border-radius: 6px;

        margin-bottom: 1rem;
        margin-left  : 5%;
        margin-right : 3%;
        cursor       : pointer;
    }

    .page #select__btn:hover {
        background-color: var(--color-primary);
        color           : var(--color-white);
    }

    .page #select__btn p {
        margin: 3%;
    }

    /* DISABLED */

    .page #disable {
        pointer-events  : none;
        background-color: #c7c7c7;
        color           : #595959;
    }

    .page #disable img {
        filter: grayscale(100%) opacity(50%);
    }

    .page #disable #select__btn {
        background-color: #858585;
        border          : none;
        color           : #ebe9e9;
    }

    b,
    strong {
        font-weight: bolder;
        color      : #06447c;
    }

    @media screen and (min-width: 768px) {
        .page body {
            font-size: 1.2rem;
        }

        .page header {
            margin-top : 5%;
            align-items: center;
            text-align : center;
        }

        .page #alt {
            width: 40%;
            height: 40px;
        }

        .page .date-carousel {
            display: none;
        }

        .page .informacoes {
            background     : #ffffff;
            border         : none;
            box-shadow     : #00000012 6px 6px 10px 6px;
            display        : flex;
            width          : 93%;
            text-align     : center;
            align-items    : center;
            justify-content: center;
            color          : black;
            font-size      : .85rem;

            text-align : center;
            align-items: center;

            margin-left  : 3.7%;
            border-radius: 5px;
            cursor       : pointer;
        }

        .page .informacoes input {
            margin       : 4px;
            border       : var(--color-primary) 1px solid;
            border-radius: 6px;
            width        : 6rem;
            margin-top   : 2.5%;
            margin-bottom: 2.5%;
        }

        .page .informacoes label {
            margin: 1px;
        }

        .page #money {
            width           : 4rem;
            border          : none;
            color           : var(--color-primary);
            background-color: #ebe9e9;
        }

        .page .informacoes .date-carousel-input {
            font-size: 12px;
            color    : black;
        }

        /* MAIN */

        .page .info {
            margin-top  : 4%;
            margin-right: 0%;
            align-items : center;
        }

        .page .txt__info {
            margin-left: 4%;
        }

        .page .info span {
            color    : #595959;
            cursor   : text;
            font-size: 13pt;
        }

        .page .info p {
            color      : #595959;
            font-weight: bold;
            cursor     : text;
            font-size  : 12pt;
        }


        /* CARD */

        .page .main__card {
            background   : #ffffff;
            margin-bottom: 10px;
            padding-top  : 5px;
            width        : 95%;
            margin       : 0 auto 15px;
            border-radius: 5px;
            box-shadow   : #00000012 6px 6px 10px 6px;
            display      : flex;
        }

        .page .main__card img {
            width : 65px;
            margin: 1rem;
        }

        .page .main__card span {
            color      : #595959;
            width      : 15px;
            font-size  : 12px;
            margin-left: 4%;
            cursor     : text;
        }

        .page .main__card p {
            font-size    : 12px;
            margin-left  : 4%;
            margin-bottom: 0%;
            cursor       : text;
        }

        .page .main__card hr {
            width: 0%;
            color: #595959;
        }

        .page .up {
            display      : flex;
            margin-bottom: 1rem;
            align-items  : center;
        }

        .up i {
            margin-left : .8rem;
            margin-right: .8rem;
            margin-top  : 2.3rem;

        }

        .page .embarque {
            margin-top: .2rem;
            text-align: center;
        }

        .page .embarque p {
            font-weight: bold;
            font-size  : 25px;
            color      : #595959;
            margin-top : 0%;
            cursor     : text;
        }

        .page .chegada {
            margin-top  : .2rem;
            margin-right: .8rem;
            text-align  : center
        }

        .page .chegada p {
            font-weight: bold;
            font-size  : 25px;
            color      : #595959;
            margin-top : 0%;
            cursor     : text;
        }

        .page .down {
            padding-bottom : 1rem;
            display        : flex;
            align-items    : center;
            justify-content: center;
        }

        .page .time {
            width       : 5rem;
            margin-left : .5rem;
            margin-right: .5rem;
            text-align  : center;
        }

        .page .time p {
            font-weight: 500;
            cursor     : text;
        }

        .page .bus__type {
            text-align: center;
        }

        .page .bus__type span {
            font-size: 10px;
            cursor   : text;
        }

        .page .bus__type p {
            width      : 6rem;
            font-weight: 500;
            cursor     : text;
        }

        .page .price {
            margin-left: .8rem;
            align-items: center;
            text-align : center
        }

        .page .price p {
            font-weight: bold;
            font-size  : 20px;
            color      : #595959;
            cursor     : text;
        }

        .page .rest {
            background-color: var(--color-primary);
            border-radius   : 6px;
            display         : flex;
            align-items     : center;
            text-align      : center;
            margin-left     : 1.5rem;
            margin-top      : 0.4rem;
            margin-right    : 12px;
        }

        .page .rest i {
            margin-left: .5rem;
        }

        .page .rest p {
            font-size    : 10px;
            font-weight  : 300;
            margin       : 4%;
            padding-right: .6rem;
            cursor       : text;
        }

        .page #select__btn {
            width           : 5em;
            height          : 30px;
            text-align      : center;
            align-items     : center;
            border          : none;
            font-weight     : bold;
            color           : var(--color-secondary);
            background-color: var(--color-white);

            text-align   : center;
            align-items  : center;
            padding-left : 0.3rem;
            padding-right: 0.3rem;
            border       : 1px solid var(--color-primary);
            border-radius: 6px;

            margin-left : 3%;
            margin-right: 4%;
            cursor      : pointer;
        }

        .page #select__btn:hover {
            background-color: var(--color-primary);
            color           : var(--color-white);
        }

        .page #select__btn p {
            margin: 0%;
        }

        /* DISABLED */

        .page #disable {
            pointer-events  : none;
            background-color: #c7c7c7;
            color           : #595959;
        }

        .page #disable img {
            filter: grayscale(100%) opacity(50%);
        }

        .page #disable #select__btn {
            background-color: #858585;
            border          : none;
            color           : #ebe9e9;
        }
    }

    @media screen and (min-width: 1024px) {
        .page body {
            font-size: 1.2rem;
        }

        .page header {
            margin-top : 5%;
            align-items: center;
            text-align : center;
        }

        .page #alt {
            display: none;
        }

        .page .date-carousel {
            display: none;
        }

        .page .informacoes {
            background     : #ffffff;
            border         : none;
            box-shadow     : #00000012 6px 6px 10px 6px;
            display        : flex;
            width          : 95%;
            text-align     : center;
            align-items    : center;
            justify-content: center;
            color          : black;
            font-size      : .85rem;

            text-align : center;
            align-items: center;

            margin-left  : 3.7%;
            border-radius: 5px;
            cursor       : pointer;
        }

        .page .informacoes input {
            margin       : 1.2%;
            border       : var(--color-primary) 1px solid;
            border-radius: 6px;
            width        : 9.5rem;
            margin-top   : 1rem;
            margin-bottom: 1rem;
        }

        .page .informacoes label {
            margin: 1px;
        }

        .page .informacoes .date-carousel-input {
            font-size: 12px;
            color    : black;
        }

        /* MAIN */

        .page .info {
            margin-top  : 4%;
            margin-right: 0%;
            align-items : center;
        }

        .page .txt__info {
            margin-left: 4%;
        }

        .page .info span {
            color    : #595959;
            cursor   : text;
            font-size: 16pt;
        }

        .page .info p {
            color        : #595959;
            font-weight  : bold;
            cursor       : text;
            font-size    : 16pt;
            margin-bottom: 0% !important;
        }


        /* CARD */

        .page .about__card {
            background    : #ffffff;
            margin-bottom : 10px;
            padding-top   : 15px;
            padding-bottom: 5px;
            width         : 20%;
            margin        : 0 auto 15px;
            margin-left   : 3%;
            margin-top    : 2.5rem;
            border-radius : 5px;
            box-shadow    : #00000012 6px 6px 10px 6px;
            z-index       : 1;
            font-size     : 10pt;
            display       : flex;
            position      : absolute;
        }

        .page .about__card ul {
            list-style    : none;
            padding-left  : 1rem;
            padding-right : 2.5rem;
            display       : flex;
            flex-direction: column;
            align-items   : flex-start;
        }

        .page .about__card li {
            font-weight: bold;
            display    : block;
        }

        .page .about__card label {
            font-size  : 8pt;
            font-weight: 500;
            margin     : 0%;
            padding    : 0%;
        }

        .page .about__card p {
            margin-top   : 0;
            margin-bottom: 0;
        }

        .page .main__card {
            background     : #ffffff;
            margin-bottom  : 10px;
            padding-top    : 14px;
            width          : 80%!important;
            margin         : 0 auto 15px;
            margin-right   : 4%;
            margin-left    : 150px;
            border-radius  : 5px;
            box-shadow     : #00000012 6px 6px 10px 6px;
            display        : flex;
            align-items    : center;
            justify-content: space-around;
            align-content  : center;
            flex-direction : row;
        }

        .page .main__card img {
            width : 65px;
            margin: 1rem;
        }

        .page .main__card span {
            color      : #595959;
            width      : 15px;
            font-size  : 12px;
            margin-left: 4%;
            cursor     : text;
        }

        .page .main__card p {
            font-size    : 12px;
            margin-left  : 4%;
            margin-bottom: 0%;
            cursor       : text;
        }

        .page .main__card hr {
            width : 0%;
            color : #595959;
            margin: 0.5rem
        }

        .page .up {
            display        : flex;
            align-items    : center;
            justify-content: center;
            align-content  : center;
        }

        .page .up i {
            margin: 1rem;
        }

        .page .embarque {
            margin-top: .2rem;
            text-align: center;
        }

        .page .embarque p {
            font-weight: bold;
            font-size  : 25px;
            color      : #595959;
            margin-top : 0%;
            cursor     : text;
        }

        .page .chegada {
            margin-top  : .2rem;
            margin-right: .8rem;
            text-align  : center
        }

        .page .chegada p {
            font-weight: bold;
            font-size  : 25px;
            color      : #595959;
            margin-top : 0%;
            cursor     : text;
        }

        .page .down {
            padding-bottom : 1rem;
            display        : flex;
            align-items    : center;
            justify-content: center;
        }

        .page .time {
            width       : 5rem;
            margin-left : .2rem;
            margin-right: .2rem;
            text-align  : center;
        }

        .page .time p {
            font-weight: 500;
            cursor     : text;
        }

        .page .bus__type {
            text-align: center;
        }

        .page .bus__type span {
            font-size: 10px;
            cursor   : text;
        }

        .page .bus__type p {
            width      : 6rem;
            font-weight: 500;
            cursor     : text;
        }

        .page .price {
            margin-left : .7rem;
            margin-right: .8rem;
            align-items : center;
            text-align  : center
        }

        .page .price p {
            font-weight: bold;
            font-size  : 20px;
            color      : #595959;
            cursor     : text;
        }

        .page .rest {
            background-color: var(--color-primary);
            border-radius   : 6px;
            display         : flex;
            align-items     : center;
            text-align      : center;
            margin-left     : 1.5rem;
            margin-top      : .4rem;
            margin-right    : 12px;
        }

        .page .rest i {
            margin-left: .5rem;
        }

        .page .rest p {
            font-size    : 10px;
            font-weight  : 500;
            padding-right: 0.6rem;
            margin       : 4%;
            cursor       : text;
        }

        .page #select__btn {
            width           : 5rem;
            height          : 30px;
            text-align      : center;
            align-items     : center;
            border          : none;
            font-weight     : bold;
            background-color: var(--color-white);
            color           : var(--color-secondary);

            text-align   : center;
            align-items  : center;
            padding-left : .3rem;
            padding-right: .3rem;

            border       : 1px solid var(--color-primary);
            border-radius: 6px;

            margin-bottom: 1rem;
            margin-left  : 0%;
            margin-top   : 0%;
            margin-right : 1rem;
            cursor       : pointer;
        }

        .page #select__btn:hover {
            background-color: var(--color-primary);
            color           : var(--color-white);
        }

        .page #select__btn p {
            margin: 0%;
        }

        /* DISABLED */

        .page #disable {
            pointer-events  : none;
            background-color: #c7c7c7;
            color           : #595959;
        }

        .page #disable img {
            filter: grayscale(100%) opacity(50%);
        }

        .page #disable #select__btn {
            background-color: #858585;
            border          : none;
            color           : #ebe9e9;
        }
    }

    @media screen and (min-width: 1440px) {
        .page body {
            font-size: 1.2rem;
        }

        .page header {
            margin-top : 5%;
            align-items: center;
            text-align : center;
        }

        .page #alt {
            display: none;
        }

        .page .date-carousel {
            display: none;
        }

        .page .informacoes {
            background     : #ffffff;
            border         : none;
            box-shadow     : #00000012 6px 6px 10px 6px;
            display        : flex;
            width          : 63.5rem;
            text-align     : center;
            align-items    : center;
            justify-content: center;
            color          : black;
            font-size      : .85rem;

            text-align : center;
            align-items: center;

            margin-left  : 13.5rem;
            border-radius: 5px;
            cursor       : pointer;
        }

        .page .informacoes input {
            margin       : 1.2%;
            border       : var(--color-primary) 1px solid;
            border-radius: 6px;
            width        : 9.5rem;
            margin-top   : 1rem;
            margin-bottom: 1rem;
        }

        .page .informacoes label {
            margin: 1px;
        }

        .page .informacoes .date-carousel-input {
            font-size: 12px;
            color    : black;
        }

        /* MAIN */

        .page .info {
            margin-top  : 4%;
            margin-right: 0%;
            align-items : center;
        }

        .page .txt__info {
            margin-left: 15.5%;
        }

        .page .info span {
            color    : #595959;
            cursor   : text;
            font-size: 16pt;
        }

        .page .info p {
            color      : #595959;
            font-weight: bold;
            cursor     : text;
            font-size  : 16pt;
        }


        /* CARD */

        .page .about__card {
            background    : #ffffff;
            margin-bottom : 10px;
            padding-top   : 15px;
            padding-bottom: 5px;
            width         : 16%;
            margin        : 0 auto 15px;
            margin-left   : 15%;
            margin-top    : 2.5rem;
            border-radius : 5px;
            box-shadow    : #00000012 6px 6px 10px 6px;
            z-index       : 1;
            font-size     : 10pt;
            display       : flex;
            position      : absolute;
        }

        .page .about__card ul {
            list-style    : none;
            padding-left  : 1rem;
            padding-right : 2.5rem;
            display       : flex;
            flex-direction: column;
            align-items   : flex-start;
        }

        .page .about__card li {
            font-weight: bold;
            display    : block;
        }

        .page .about__card label {
            font-size  : 8pt;
            font-weight: 500;
            padding    : 0%;
            display    : block;
        }

        .page .main__card {
            background     : #ffffff;
            margin-bottom  : 10px;
            padding-top    : 14px;
            width          : 70%;
            margin         : 0 auto 15px;
            margin-left    : 200px !important;
            margin-right   : 4%;
            border-radius  : 5px;
            box-shadow     : #00000012 6px 6px 10px 6px;
            display        : flex;
            align-items    : center;
            justify-content: space-around;
            align-content  : center;
            flex-direction : row;
        }

        .page .main__card img {
            width : 65px;
            margin: 1rem;
        }

        .page .main__card span {
            color      : #595959;
            width      : 15px;
            font-size  : 12px;
            margin-left: 4%;
            cursor     : text;
        }

        .page .main__card p {
            font-size    : 12px;
            margin-left  : 4%;
            margin-bottom: 0%;
            cursor       : text;
            margin-top   : 0.5rem;
        }

        .page .main__card hr {
            width : 0%;
            color : #595959;
            margin: 0.5rem
        }

        .page .up {
            display        : flex;
            flex-direction : row;
            align-items    : center;
            justify-content: center;
            align-content  : center;
            margin-bottom  : 1rem;
        }

        .page .up i {
            margin-left : .8rem;
            margin-right: .8rem;
            margin-top  : .3rem;
        }

        .page .embarque {
            margin-top: .2rem;
            text-align: center;
        }

        .page .embarque p {
            font-weight: bold;
            font-size  : 25px;
            color      : #595959;
            margin-top : 0%;
            cursor     : text;
        }

        .page .chegada {
            margin-top  : .2rem;
            margin-right: .8rem;
            text-align  : center
        }

        .page .chegada p {
            font-weight: bold;
            font-size  : 25px;
            color      : #595959;
            margin-top : 0%;
            cursor     : text;
        }

        .page .down {
            padding-bottom : 1rem;
            display        : flex;
            align-items    : center;
            justify-content: center;
        }

        .page .time {
            width       : 5rem;
            margin-left : .2rem;
            margin-right: .2rem;
            text-align  : center;
        }

        .page .time p {
            font-weight: 500;
            cursor     : text;
        }

        .page .bus__type {
            text-align: center;
        }

        .page .bus__type span {
            font-size: 10px;
            cursor   : text;
        }

        .page .bus__type p {
            width      : 6rem;
            font-weight: 500;
            cursor     : text;
        }

        .page .price {
            margin-left : .7rem;
            margin-right: .8rem;
            align-items : center;
            text-align  : center
        }

        .page .price p {
            font-weight: bold;
            font-size  : 20px;
            color      : #595959;
            cursor     : text;
        }

        .page .rest {
            background-color: var(--color-primary);
            border-radius   : 6px;
            display         : flex;
            align-items     : center;
            text-align      : center;
            margin-left     : 1.5rem;
            margin-top      : .4rem;
            margin-right    : 12px;
        }

        .page .rest i {
            margin-left: .5rem;
        }

        .page .rest p {
            font-size  : 10px;
            font-weight: 300;
            margin     : 4%;
            cursor     : text;
        }

        .page #select__btn {
            width           : 5rem;
            height          : 30px;
            text-align      : center;
            align-items     : center;
            border          : none;
            font-weight     : bold;
            background-color: var(--color-white);
            color           : var(--color-secondary);
            text-align      : center;
            align-items     : center;
            padding-left    : 0.3rem;
            padding-right   : 0.3rem;
            border          : 1px solid var(--color-primary);
            border-radius   : 6px;
            margin-left     : 0%;
            margin-top      : 0%;
            margin-right    : 0%;
            cursor          : pointer;
        }

        .page #select__btn:hover {
            background-color: var(--color-primary);
            color           : var(--color-white);
        }

        .page #select__btn p {
            margin: 0%;
        }

        /* DISABLED */

        .page #disable {
            pointer-events  : none;
            background-color: #c7c7c7;
            color           : #595959;
        }

        .page #disable img {
            filter: grayscale(100%) opacity(50%);
        }

        .page #disable #select__btn {
            background-color: #858585;
            border          : none;
            color           : #ebe9e9;
        }
    }
}

.modal-session {
    width          : 100%;
    position       : fixed;
    top            : 0px;
    left           : 0px;
    height         : 100vh;
    background     : rgba(0, 0, 0, 0.5);
    z-index        : 99;
    display        : flex;
    align-items    : center;
    justify-content: center;
    text-align     : center;

    .content {
        background   : #fff;
        padding      : 60px;
        border-radius: 10px;

        p {
            font-weight: bold;
            font-size  : 20px;
            color      : var(--color-secundary);
        }

        span {
            display   : block;
            text-align: center;
            width     : 100%;
            margin-top: 40px;
        }

        a {
            background-color: var(--color-secundary);
            border-radius   : 20px;
            color           : #fff;
            padding         : 10px 30px;
            border          : none;
            margin-top      : 40px;
        }
    }
}