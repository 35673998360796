.horarios {
    * {
        align-items    : center;
        text-align     : center;
        justify-content: center;
    }

    h1 {
        padding-top: 1rem;
    }

    .tabela {
        padding-bottom: 1rem;
        align-items   : center;
        display       : flex;
        align-items   : flex-start;
    }

    #week {
        font-weight: 500;
    }

    table,
    td {
        border       : .5px solid black;
        margin       : 1rem;
        padding-left : 1rem;
        padding-right: 1rem;
    }

    thead,
    tfoot {
        background-color: #FFC512;
        color           : black;
    }

    table,
    th {
        font-weight: 700;
    }

    table,
    tr {
        margin: 1rem;
    }

    table {
        margin-bottom: 2rem;
    }

    .left {
        display       : flex;
        align-items   : center;
        flex-direction: column;
    }

    .right {
        display       : flex;
        align-items   : center;
        flex-direction: column;
    }

    .left p {
        margin-top      : -2rem;
        background-color: #e2e2e2;
        width           : 94%;
    }

    .right p {
        margin-top      : -2rem;
        background-color: #e2e2e2;
        width           : 94%;
    }
}