.containerText {
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin-top: 30px;
    margin-bottom: 30px;
    h1 {
      color: var(--color-secundary);
      font-size: 25px;
      font-weight: bold;
    }
  }
  .text {
    white-space: break-spaces;
    line-height: 30px;
    min-height: 400px;
    text-align: justify;
    margin-bottom: 80px;
    p {
      font-size: 16px;
      text-align: justify;
      line-height: 30px;
    }
    .subTitle {
      font-size: 18px;
      font-weight: bold;
      font-size: 16px;
      text-align: justify;
      line-height: 30px;
    }

    .policyText {
      width: 100%;
      height: 1024px;
      margin-bottom: 30px;
      border: 0;
    }
  }
}
