.bannerHome {
  background-size: cover !important;
  position: relative;
  min-height: 500px;
  .formBusca {
    margin-top: 30px;
    position: relative;
    z-index: 2;
    h2 {
      font-size: 18px;
      color: #fff;
      font-weight: bold;
    }
    .boxform {
      padding: 10px;
      border-radius: 10px;
      background: #fff;
      display: flex;
      // flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      button.btnBuscarPassagem {
        background: var(--color-secundary);
        padding: 20px;
        border-radius: 10px;
        color: #fff;
        border: none;
        min-width: 250px;
        height: 100%;
        font-weight: bold;
        font-size: 18px;
      }
      .cardForm {
        display: flex;
        padding: 10px;
        flex-direction: column;
        border: solid 1px var(--color-secundary);
        border-radius: 10px;
        justify-content: center;
        label {
          font-weight: normal;
          margin-bottom: 10px;
          color: var(--color-secundary);
        }
        input {
          border: none;
        }
        .select2 {
          min-width: 200px;
          margin: 0px !important;
          .select2-selection {
            border: none;
            .select2-selection__arrow {
              display: none;
            }
          }
        }
        &.cardFormNoBoard {
          border: none;
          label {
            margin-bottom: 0px;
          }
          input {
            margin-right: 10px;
            position: relative;
            &::after{
              position: absolute;
              content: "";
              width: 15px;
              height: 15px;
              background: #fff;
              top: -1px;
              left: -1px;
              border-radius: 50%;
              border: solid 1px var(--color-secundary);
            }
            &:checked {
              &::after{
                background: var(--color-secundary);
              }
            }
          }
        }
      }
      .cardFormDate {
        border: none;
        padding: 0px;
        .react-datepicker-wrapper {
          margin-bottom: 5px;
          input {
            background: url(../../assets/img/iconDate.png) no-repeat;
            background-position: 5px 3px;
            border-radius: 10px;
            padding: 5px 5px 5px 35px;
            border: solid 1px var(--color-secundary);
          }
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .carousel-root{
    position: absolute;
    top: 0px;
    z-index: 1;
    .thumbs{
      display: none;
    }
  }
}

.select2-results__option{
  color: var(--color-secundary);
  text-transform: capitalize !important;
  font-size: 12px;
  padding: 10px !important;
  display: block;
  font-weight: 500;
}
.select2-results__option--highlighted{
  background: var(--color-secundary) !important;
  color: #fff;
}

.select2-container .select2-selection--single{
  height: auto !important;
}

.select2-selection__rendered{
  font-size: 14px;
  color: var(--color-secundary) !important;
  font-weight: 500;
  line-height: 17px !important;
}

.select2-results__option[aria-live="assertive"] {
  top: -20px;
  position: relative;
  &::before{
    content: "Nenhum resultado encontrado";
    position: absolute;
    top: 30px;
    left: 10px;
  }
}

.carousel.carousel-slider{
  height: 500px;
  .slide {
    img{
      height: 500px;
      object-fit: cover;
    }
  }
  .control-dots {
   li{
     width: 15px;
     height: 15px;
     border: solid 2px #fff;
     background: var(--color-secundary);
   }
  }
}
