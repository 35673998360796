.pdf417 {
    transform  : rotate(90deg);
    width      : 280px;
    height     : 50px;
    margin-top : 130px;
    margin-left: -100px;
}

@media only screen and (max-width: 720px) {
    .pdf417 {
        transform  : rotate(90deg);
        width      : 360px;
        height     : 50px;
        margin-top : 165px;
        margin-left: -150px;
    }
}

@media only screen and (min-width: 280px) {
    .pdf417 {
        transform  : rotate(90deg);
        width      : 280px;
        height     : 50px;
        margin-top : 130px;
        margin-left: -100px;
    }
}

@media only screen and (max-width: 280px) {
    .pdf417 {
        transform  : rotate(90deg);
        width      : 280px;
        height     : 50px;
        margin-top : 130px;
        margin-left: -115px;
    }
}


.pdf417_withQR {
    transform  : rotate(90deg);
    width      : 200px;
    height     : 45px;
    margin-top : 65px;
    margin-left: -55px;
}

.id_qrcode {
    padding    : 25px;
    margin-left: -20px;
    margin-top : -10px;
}

@media only screen and (max-width: 870px) {
    .pdf417_withQR {
        transform  : rotate(90deg);
        width      : 270px;
        height     : 45px;
        margin-top : 85px;
        margin-left: -103px;
    }
    
    .id_qrcode {
        padding    : 35px;
        margin-left: -32px;
        margin-top : -10px;
    }
}

@media only screen and (min-width: 280px) {
    .pdf417_withQR {
        transform  : rotate(90deg);
        width      : 200px;
        height     : 45px;
        margin-top : 65px;
        margin-left: -65px;
    }
    
    .id_qrcode {
        padding    : 25px;
        margin-left: -25px;
        margin-top : -10px;
    }
}

@media only screen and (max-width: 280px) {
    .pdf417_withQR {
        transform  : rotate(90deg);
        width      : 200px;
        height     : 45px;
        margin-top : 65px;
        margin-left: -55px;
    }
    
    .id_qrcode {
        padding    : 25px;
        margin-left: -20px;
        margin-top : -10px;
    }
}
