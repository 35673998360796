.marcaCaoBus {
    list-style: none;
    display   : flex;


    .onibus {
        background     : url(../../../../assets/img/onibus_mobile_5x.svg) no-repeat center;
        background-size: 100% auto;
        transform      : rotate(90deg);
        width          : 160%;
        height         : 280px;
        margin-left    : -30%;
        margin-top     : 30%;
        padding-left   : 26px;

        .left__side {
            padding     : 55px;
            padding-left: 27px;
        }
    }

    .alinhamento {
        display   : flex;
        margin-top: 120px;
        width     : 100%;
        flex-wrap : wrap;

        .legend {
            display: flow-root;
            margin : 20px 0px;
            width  : 100%;
        }

        .info {
            .assentos {
                margin-top: 10px;
                width     : 50%;
                display   : contents;
            }
        }

        .action {
            justify-content: center;
            margin-left    : 22%;
            padding-top    : 15px;
        }
    }

    svg {
        width: 30px;
    }

    .livre {
        path {
            fill: #a2c058;
        }

        path:nth-child(2) {
            fill: #91af4b;
        }
    }

    .mulher {
        path {
            fill: #e681b2;
        }

        path:nth-child(2) {
            fill: #ea69a4;
        }
    }

    .active {
        path {
            fill: #ffd85f;
        }

        path:nth-child(2) {
            fill: #91af4b;
        }
    }

    .active-mulher {
        path {
            fill: #ffd85f;
        }

        path:nth-child(2) {
            fill: #ea69a4;
        }
    }

    &.notFound {
        cursor: initial;

        path {
            fill: #fff;
        }

        path:nth-child(2) {
            fill: #fff;
        }
    }

    .assento {
        position       : relative;
        align-items    : center;
        justify-content: center;
        color          : rgb(255, 255, 255);
        z-index        : 1050;
        margin-bottom  : 20%;
    }

    .assento span {
        position   : absolute;
        z-index    : 4;
        font-size  : 0.8rem;
        margin-top : 5px;
        margin-left: 4px;
        transform  : rotate(270deg);
    }

    ul {
        display: flex;
        margin : 0;

        li {
            padding-left  : 6px;
            padding-right : 4px;
            padding-top   : 2px;
            padding-bottom: 10px;
            margin-bottom : 0px;
            margin-left   : 0px;
        }
    }

    // #off {
    //     display: none;
    // }

    // .left__side ul {
    //     display     : grid;
    //     padding-left: 1%;
    // }

    // .right__side {
    //     display: grid;
    // }

}
@media screen and (min-width: 768px) {
    .marcaCaoBus {
        list-style: none;
        display   : flex;
        justify-content: center;
    
    
        .onibus {
            background     : url(../../../../assets/img/onibus_mobile_5x.svg) no-repeat center;
            background-size: 100% auto;
            transform: rotate(180deg);
            width: 80%;
            height: auto;
            margin-left: 0%;
            margin-top: 0%;
            padding-left: 26px;
    
            .left__side {
                padding     : 55px;
                padding-left: 27px;
            }
        }
    
        .alinhamento {
            display   : flex;
            margin-top: 30px;
            width     : 100%;
            flex-wrap : wrap;
    
            .legend {
                display: flow-root;
                margin : 20px 0px;
                width  : 100%;
            }
    
            .info {
                .assentos {
                    margin-top: 10px;
                    width     : 50%;
                    display   : contents;
                }
            }
    
            .action {
                justify-content: center;
                margin-left    : 22%;
                padding-top    : 15px;
            }
        }
    
        svg {
            width: 30px;
        }
    
        .livre {
            path {
                fill: #a2c058;
            }
    
            path:nth-child(2) {
                fill: #91af4b;
            }
        }
    
        .mulher {
            path {
                fill: #e681b2;
            }
    
            path:nth-child(2) {
                fill: #ea69a4;
            }
        }
    
        .active {
            path {
                fill: #ffd85f;
            }
    
            path:nth-child(2) {
                fill: #91af4b;
            }
        }
    
        .active-mulher {
            path {
                fill: #ffd85f;
            }
    
            path:nth-child(2) {
                fill: #ea69a4;
            }
        }
    
        &.notFound {
            cursor: initial;
    
            path {
                fill: #fff;
            }
    
            path:nth-child(2) {
                fill: #fff;
            }
        }
    
        .assento {
            position       : relative;
            align-items    : center;
            justify-content: center;
            color          : rgb(255, 255, 255);
            z-index        : 1050;
            margin-bottom  : 20%;
        }
    
        .assento span {
            position   : absolute;
            z-index    : 4;
            font-size  : 0.8rem;
            margin-top : 5px;
            margin-left: 4px;
            transform  : rotate(270deg);
        }
    
        ul {
            display: flex;
            margin : 0;
    
            li {
                padding-left  : 6px;
                padding-right : 4px;
                padding-top   : 2px;
                padding-bottom: 10px;
                margin-bottom : 0px;
                margin-left   : 0px;
            }
        }
    
        // #off {
        //     display: none;
        // }
    
        // .left__side ul {
        //     display     : grid;
        //     padding-left: 1%;
        // }
    
        // .right__side {
        //     display: grid;
        // }
    
    }    
    .passagem_mobile .page .main__card{
        background: #ffffff;
        margin-bottom: 10px;
        padding-top: 5px;
        width: 95%;
        margin: 0 auto 15px;
        border-radius: 5px;
        box-shadow: #00000012 6px 6px 10px 6px;
        display: flex;
        flex-direction: column;
    }
    #ticket__info{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

@media screen and (min-width: 1024px) {
    .marcaCaoBus {
        list-style: none;
        display   : flex;
        justify-content: center;
    
    
        .onibus {
            background     : url(../../../../assets/img/onibus_mobile_5x.svg) no-repeat center;
            background-size: 100% auto;
            transform: rotate(180deg);
            width: 70%;
            height: auto;
            margin-left: 0%;
            margin-top: 0%;
            padding-left: 26px;
    
            .left__side {
                padding     : 55px;
                padding-left: 27px;
            }
        }
    
        .alinhamento {
            display   : flex;
            margin-top: 30px;
            width     : 100%;
            flex-wrap : wrap;
    
            .legend {
                display: flow-root;
                margin : 20px 0px;
                width  : 100%;
            }
    
            .info {
                .assentos {
                    margin-top: 10px;
                    width     : 50%;
                    display   : contents;
                }
            }
    
            .action {
                justify-content: center;
                margin-left    : 22%;
                padding-top    : 15px;
            }
        }
    
        svg {
            width: 30px;
        }
    
        .livre {
            path {
                fill: #a2c058;
            }
    
            path:nth-child(2) {
                fill: #91af4b;
            }
        }
    
        .mulher {
            path {
                fill: #e681b2;
            }
    
            path:nth-child(2) {
                fill: #ea69a4;
            }
        }
    
        .active {
            path {
                fill: #ffd85f;
            }
    
            path:nth-child(2) {
                fill: #91af4b;
            }
        }
    
        .active-mulher {
            path {
                fill: #ffd85f;
            }
    
            path:nth-child(2) {
                fill: #ea69a4;
            }
        }
    
        &.notFound {
            cursor: initial;
    
            path {
                fill: #fff;
            }
    
            path:nth-child(2) {
                fill: #fff;
            }
        }
    
        .assento {
            position       : relative;
            align-items    : center;
            justify-content: center;
            color          : rgb(255, 255, 255);
            z-index        : 1050;
            margin-bottom  : 20%;
        }
    
        .assento span {
            position   : absolute;
            z-index    : 4;
            font-size  : 0.8rem;
            margin-top : 5px;
            margin-left: 4px;
            transform  : rotate(270deg);
        }
    
        ul {
            display: flex;
            margin : 0;
    
            li {
                padding-left  : 6px;
                padding-right : 4px;
                padding-top   : 2px;
                padding-bottom: 10px;
                margin-bottom : 0px;
                margin-left   : 0px;
            }
        }
    
        // #off {
        //     display: none;
        // }
    
        // .left__side ul {
        //     display     : grid;
        //     padding-left: 1%;
        // }
    
        // .right__side {
        //     display: grid;
        // }
    
    }    
    .passagem_mobile .page .main__card{
        background: #ffffff;
        margin-bottom: 10px;
        padding-top: 5px;
        width: 95%;
        margin: 0 auto 15px;
        border-radius: 5px;
        box-shadow: #00000012 6px 6px 10px 6px;
        display: flex;
        flex-direction: column;
    }
    #ticket__info{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}