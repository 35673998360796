@media only screen and (max-width: 1200px) {
  // Header
  .containerHeader {
    flex-wrap: wrap;
    .logos {
      // width: 100%;
      display: flex;
      justify-content: space-between;
      img {
        width: 45px;
        height: 43px;
        object-fit: cover;
        object-position: left;
      }
    }
    button.menu-responsive {
      display: block;
      background: transparent;
      cursor: pointer;
      height: 25px;
      width: 30px;
      border: none;
      border-bottom: solid 5px var(--color-secundary);
      position: relative;
      &::after,
      &::before {
        content: "";
        position: absolute;
        background: transparent;
        width: 30px;
        border: none;
        border-bottom: solid 5px var(--color-secundary);
        top: 0px;
        left: 0px;
      }
      &::before {
        top: 10px;
      }
    }
    nav {
      width: 100%;
      justify-content: flex-end;
      transition: all 0.5s ease-in-out;
      ul {
        flex-wrap: wrap;
        width: 100%;
        margin-top: 20px;
        justify-content: flex-end;
        li {
          margin: 0px;
          // width: 100%;
          margin-left: 0px !important;
          margin-bottom: 10px !important;
          text-align: right;
          margin-left: 20px !important;
          a {
          }
        }
      }
      &::before,
      &::after {
        // display: none;
      }
      &.closed {
        max-height: 0px;
        overflow: hidden;
      }
      &.open {
        max-height: 300px;
        overflow: hidden;
      }
    }
  }

  // BANNER HOME
  .bannerHome {
    height: 550px;
    .formBusca {
      .boxform {
        flex-wrap: wrap;
        .cardForm {
          width: 100%;
          margin-bottom: 20px;
        }
        .cardFormDate {
          input {
            width: 100%;
            padding: 10px 40px !important;
            background-position: 10px 9px !important;
          }
        }
        button {
          width: 100%;
        }
      }
    }
    .carousel.carousel-slider {
      height: 550px;
      .slide {
        img {
          height: 550px;
        }
      }
    }
  }

  // ICONES HOME
  .chamadaHome {
    .item {
      width: 100%;
      flex: auto;
      border-bottom: solid 1px #c6c6c6;
      margin-bottom: 20px;
      &::after {
        display: none;
      }
    }
  }

  // BANNERS HOMES
  .destinos {
    .box-destinos {
      .item {
        width: 100%;
        padding: 0px;
        &:first-child,
        &:last-child {
          width: 100%;
        }
        img {
          object-fit: cover;
        }
      }
      // .item:nth-child(6n), .destinos .box-destinos .item:first-child
    }
  }

  // QUEM SOMOS HOME
  .kmRodados {
    padding: 10px;
    .boxKmRodados {
      flex-wrap: wrap;
      .left,
      .right {
        width: 100%;
        .box-value {
          width: 100%;
          p.km {
            font-size: 30px !important;
          }
        }
      }
    }
  }

  // MAPAS
  .containerMapsRotas {
    flex-wrap: wrap;
    .left,
    .right {
      width: 100%;
    }
    .right {
      padding: 10px;
      .hearderMapaseRotas {
        flex-wrap: wrap;
        .titleSemiBold {
          width: 100%;
          margin-bottom: 30px;
        }
      }
      .containerResultMaps {
        .estadosMaps {
          .item {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }

  // PASSAGENS
  .pagePassagens {
    .steps {
      flex-wrap: wrap;
      width: 95%;
      .item {
        width: 100%;
        flex: auto;
        margin-bottom: 20px;
        border: solid 1px #c6c6c6;
        &::before,
        &::after {
          transform: rotate(90deg);
          right: 50%;
          margin-right: -17px;
          top: 100%;
        }
      }
    }
    .search-results {
      .filters {
        position: absolute;
        z-index: 99;
        background: #fff;
        transition: all 0.5s ease-in-out;
        margin-top: 50px;
        margin-left: -120%;
        height: auto;
        &.open {
          margin-left: 0px;
        }
      }
      .sort-results {
        display: none;
      }
      .results {
        .actionsResponsive {
          display: flex;
          justify-content: space-between;
          button {
            background-color: var(--color-secundary);
            border-radius: 20px;
            color: #fff;
            padding: 10px 30px;
            border: none;
          }
        }
        .search-results {
          z-index: 99;
          background: #fff;
          transition: all 0.5s ease-in-out;
          width: 80%;
          position: absolute;
          margin-left: -120%;
          &.open {
            margin-left: 0px;
          }
        }
        .date-tabs {
          flex-wrap: wrap;
          .date-tab {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              width: auto;
              margin-left: 20px;
            }
            &.activated {
              background: var(--color-secundary);
              a,
              span {
                color: #fff !important;
              }
            }
          }
        }
        .result-results-box {
          flex-wrap: wrap;
          padding-bottom: 40px;
          margin-bottom: 20px;
          .result-results-box-align {
            flex-wrap: wrap;
            > div {
              width: 50%;
              margin: 0px;
            }
          }
          .footer {
            display: flex;
            align-items: center;
            width: 100%;
            flex-wrap: wrap;
            hr {
              width: 100%;
            }
            .col-md-6 {
              width: 60%;
              padding: 0px 10px;
              margin-bottom: 13px;
              &.pontronasLivres {
                width: 40%;
                text-align: center;
              }
              .col-md-6 {
                width: 100%;
              }
            }
          }
          .marcaCaoBus {
            .busMask {
              background: url(../img/onibus.jpg) no-repeat top;
              background-size: 92% auto;
              min-height: 910px;
              padding-left: 20px;
              padding-right: 20px;
              padding-top: 100px;
              .fileiras {
                flex-direction: row;
                div.cadeira {
                  width: 17%;
                  height: auto;
                  svg {
                    transform: rotate(90deg);
                    width: 100%;
                    height: auto;
                  }
                  span {
                    z-index: 9;
                    top: 10%;
                    width: 100%;
                  }
                }
              }
            }
            .alinhamento {
              .info {
                .assentos {
                  width: 100%;
                  .item {
                    width: 100%;
                    margin-bottom: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .pagePassagens {
    .container {
      .container {
        padding: 0px;
      }
    }
  }

  .grid-div {
    flex-wrap: wrap;
    .input {
      width: 100%;
      .form-wrap,
      .form-box-payment {
        margin: 0px;
        padding: 0px;
      }
      .form-box-payment {
        padding-top: 20px;
      }
    }
    .resume,
    .form-group.col-md-6,
    .form-group.col-md-12,
    .form-group.col-md-5,
    .form-group.col-md-8,
    .form-group.col-md-10,
    .form-group.col-md-3,
    .form-group.col-md-4,
    .form-group.col-md-2 {
      width: 100%;
    }
  }

  .grid-div-logged {
    flex-wrap: wrap;
    .menu-logged {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }
    .container-info {
      width: 100%;
    }
  }

  .container-info {
    .box-container-info {
      .box-info {
        flex-wrap: wrap;
        .info-right {
          width: 100%;
          height: auto;
          button {
            width: 100%;
          }
        }
      }
    }
  }

  .div-container {
    flex-wrap: wrap;
    .container {
      width: 100%;
    }
    .map {
      width: 100%;
      .divContainerImg {
        width: 100% !important;
        img {
          object-fit: cover;
        }
      }
    }
    .buttons {
      .large {
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }

  footer {
    .footer-yellow {
      flex-wrap: wrap;
      .boxFooter {
        flex-wrap: wrap;
        margin: 0px;
        width: 100%;
        nav {
          min-width: 100%;
          width: 100%;
          flex-wrap: wrap;
          ul {
            margin: 0px;
            width: 100%;
            flex: auto;
            margin-bottom: 30px;
          }
        }
        a.goSearch {
          margin-left: 0px;
          display: block;
          margin: 40px 0px;
          max-width: 100%;
          width: 100%;
        }
        .sac {
          span {
            margin: 0px;
            height: auto;
          }
          .boxSac {
            margin: 0px;
          }
        }
      }
    }
    .footer-white {
      .boxFooterWhite {
        flex-wrap: wrap;
        nav {
          width: 100%;
          ul {
            margin-right: 0px;
            justify-content: center;
            text-align: center;
          }
        }
        .social {
          width: 100%;
        }
      }
    }
  }
}
