.containerMapsRotas{
  display: flex;
  margin: 40px 0px;
  .left{
    width: 50%;
    height: 580px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 95%;
    }
  }
  .right{
    width: 50%;
    height: 580px;
    display: flex;
    flex-direction: column;
    padding: 40px;
    flex: 1;
    .hearderMapaseRotas{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .titleSemiBold{
        text-align: center;
        font-size: 40px;
        font-weight: 400;
        color: var(--color-dark);
        margin: 0px;
      }
      p{
        text-align: center;
        padding-left: 20px;
        color: var(--color-secundary);
        font-weight: 400;
        .semiBold{
          display: block;
          text-align: right;
          font-size: 18px;
          font-weight: 400;
          color: var(--color-secundary);
          &:first-child{
            font-size: 65px;
          }
        }
        .bold{
          display: block;
          text-align: right;
          font-size: 60px;
          font-weight: bold;
          color: var(--color-secundary);
        }
      }
      
    }

    select{
      width: 50%;
      margin: 20px auto;
      padding: 10px;
      color: #fff;
      background-color: var(--color-secundary);
      border: 0;
    }
    .containerResultMaps{
      max-height: 500px;
      display: block;
      overflow: auto;
      .estadosMaps{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .item{
          width: 33%;
          margin-bottom: 20px;
          padding-right: 20px;
          .titleOrigin{
            color: var(--color-secundary);
            font-size: 18px;
            margin-bottom: 10px;
            display: block;
            font-weight: bold;
          }
          span{
            color: var(--color-secundary);
            font-size: 18px;
            margin-bottom: 10px;
            display: block;
            font-weight: bold;
          }
          ul{
            li{
              color: var(--color-dark);
              font-weight: normal;
              font-size: 18px;
              line-height: 25px;
            }
          }
  
        }
      }
    }
    .itemLink{
      margin-top: 30px;
      width: 100%;
    }
    .linkSeeMoreDestinations {
      color: var(--color-secundary);
    }
  }

  #svg-map path { fill:#FFC512 }
  #svg-map .desable { fill:#dbdbdb}
  #svg-map text { fill:#fff; font:12px Arial-BoldMT, sans-serif; cursor:pointer }
  #svg-map a{ text-decoration:none }
  #svg-map a:hover { cursor:pointer; text-decoration:none }
  #svg-map a:hover path{ fill:#f7b500 !important }
  #svg-map .circle { fill:#ffe085}
  #svg-map .circleDesable { fill:#cccccc}
  #svg-map a:hover .circle { fill:#f7b500 !important; cursor:pointer }

  // #svg-map a:active path{ fill: var(--color-secundary) !important }
  // #svg-map a:focus path{ fill: var(--color-secundary) !important }

  #svg-map path.active{ fill: var(--color-secundary) !important }
  #svg-map a:focus path{ fill: var(--color-secundary) !important }
}

.seeMoreDestinations{
  width: 100%;
  min-height: 200px;
  overflow:auto;

  display: flex;
  flex-direction: column;
  padding: 30px;
  flex: 1;

  .titileMoreDestinations{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--color-secundary);
    font-size: 15px;
  }

  .listDestinations{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 16px;
    word-wrap: break-word;
    padding-top: 10px;
  }


  .listEstadosMaps{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
    .listItem{
      width: 25%;
      margin-bottom: 10px;
      padding-right: 10px;
      .linkRoutes{
        color: var(--color-secundary);
        font-size: 17px;
        margin-bottom: 10px;
        display: block;
        font-weight: 400;
      }
      a.linkRoutes:hover{
        font-weight: bold;
      }
      span{
        color: var(--color-secundary);
        font-size: 18px;
        display: block;
        font-weight: bold;
      }
      
      p{
        color: var(--color-dark);
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;

      }
      ul{
        li{
          color: var(--color-dark);
          font-weight: normal;
          font-size: 18px;
          line-height: 25px;
         
        }
      }
    }
  }
}
