.header {
  background: var(--color-primary);

  .containerHeader {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    padding        : 20px 0px;

    .profileUser {
      small {
        display   : block;
        text-align: right;
      }
    }

    .logos {
      img {
        margin-right: 20px;
        height      : 40px;
        cursor      : pointer;
      }
    }

    .nav {
      display: flex;

      ul {
        display    : flex;
        align-items: center;
        margin-bottom: 0 !important;


        li {
          font-size  : 20px;
          color      : var(--color-secundary);
          margin-left: 20px;

          a {
            color      : var(--color-secundary);
            margin-left: 20px;
          }

          button {
            background-color: var(--color-secundary);
            border-radius   : 20px;
            color           : #fff;
            padding         : 10px 30px;
            border          : none;

            &:hover {
              position  : relative;
              left      : -1px;
              top       : -1px;
              box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
            }
          }
        }
      }
    }
  }
}