.otherResults {
  border: solid 1px #FFFF;
  border-radius: 10px;
  box-shadow: #00000012 6px 6px 10px 6px;
  margin-bottom: 40px;
  padding-bottom: 20px;
  >h2{
      text-align: center;
      margin: 20px 0px;
      font-size: 16px;
  }
.items {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  .item{
      width: 220px;
      height: 220px;
      background-size: cover !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      flex-wrap: wrap;
      a{
      display: flex;
      width: 100%;
      height: 100%;
      font-size: 14px;
      color: #fff;
      font-weight: bold;
      align-items: center;
      }
      p{
      display: block;
      text-align: center;
      vertical-align: middle;
      width: 100%;
      }
  }
}
}
