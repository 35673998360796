.formLogin {
  margin-top: 80px;
  margin-bottom: 80px;
  .actions{
    span{
      display: block;
      // margin-bottom: 10px;
      margin-top: 20px;
    }
    a{
      color: var(--color-secundary);
      font-weight: bold;
    }
  }
  form {
    margin-top: 80px;
    .error{
      border-color: var(--color-alert);
    }
    .nice{
      border-color: var(--color-success);
    }
  }
  .loginRedes {
    display: flex;
    flex-wrap: wrap;
    button {
      width: 100%;
      margin-bottom: 20px;
      margin-top: 10px;
      padding: 15px;
      color: #fff;
      border: none;
      border-radius: 7px;
      font-size: 18px;
      background: var(--color-secundary);
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      // font-weight: bold;
      &.facebook {
        background: #3b5998;
        img {
          height: 20px;
          margin: 5px 10px;
        }
      }
      &.google {
        background: #fff;
        color: rgba(0, 0, 0, 0.54);
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
        font-weight: 400;
        img {
          height: 25px;
          margin-right: 10px;
        }
      }
      &:hover {
        position: relative;
        top: -1px;
        left: -1px;
      }
    }
    hr {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
    .divisor {
      text-align: center;
      font-weight: 400;
      font-size: 18px;
      display: inline-block;
      background: #fff;
      padding: 0px 20px;
      margin: 0 auto;
      margin-top: -30px;
    }
  }
}
