.kmRodados{
  background: url(../../assets/img/bgEstrela.png);
  padding: 80px;
  .boxKmRodados{
    display: flex;

    .left, .right{
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
    .left{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .box-value{
        margin: 5px;
        width: 75%;
        background: #fff;
        padding: 30px 30px;
        text-align: center;
        border-radius:  20px;
        border: solid 3px var(--color-dark);
        h2{
          font-size: 12px;
          font-weight: bold;
          font-size: 50px;
          line-height: 50px;
        }
        span{
          font-size: 20px;
          margin-top: -10px;
          position: relative;
          display: block;
        }
        .km{
          font-weight: bold;
          font-size: 45px;
          line-height: 50px; 
          margin-bottom: 12px;
          color: var(--color-secundary)
        }
      }
    }
    .right{
      align-items: center;
      h2{
        margin-top: 20px;
        font-weight: bold;
        font-size: 22px;
        color: var(--color-dark)
      }
      p{
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 20px;
        text-align:justify;
      }
      .whoWe{
        white-space: break-spaces;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 25px;
        text-align:justify;
      }
    }
  }
}