.container-info {
  width: 70%;

  .box-title-logged {
    justify-content: flex-start;
    align-items: flex-start;
    .title-logged {
      color: var(--color-secundary);
      font-size: 37px;
      font-weight: 400;
      text-align: left;
      margin-bottom: 60px;
    }
    .container-buttons {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .btn-header-logged {
        color: #ccc;
        font-size: 20px;
        border-radius: 0px;
        border: 1px solid #d6d3d3;
        background-color: #ffffff;
        padding: 0px;
        .btnTextHeader {
          font-size: 20px;
          padding: 10px 20px;
          &.active {
            color: var(--color-secundary);
            font-weight: 500;
            background: var(--color-primary);
          }
        }
      }
    }
  }

  .box-container-info {
    border: solid 1px #d6d3d3;
    // height: 500px;
    margin-top: -1px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // overflow: auto;
    padding-bottom: 10px;

    .box-info {
      border: solid 1px #d6d3d3;
      width: 97%;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      .info-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 75%;
        padding: 10px;
        .rota{
          margin-top: 20px;
        }
      }
      .info-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        width: 25%;
        padding: 10px;
        height: 110px;

        .btn-show-ticket {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 3px;
          padding-bottom: 3px;
          color: var(--color-secundary);
          background: var(--color-primary);
        }
      }

      .text-info-logged {
        color: #696969;
        padding-top: 5px;
        font-size: 14px;
      }
    }
  }
}
