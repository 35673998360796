.bilhete {
    font-weight: 500;
    font-size  : small;

    .passageiro {
        border-right: 0.8px solid #000;
    }

    .motorista {
        border-left: 0.8px dashed #000;
    }

    @media only screen and (max-width: 575px) {
        .motorista {
            border-top : 0.8px dashed #000;
            border-left: 0px;
            padding-top: 5px;
        }
    }

    .via {
        font-size : 9px;
        text-align: center;
    }

    .direitos {
        font-size : 7px;
        text-align: justify;
        line-height: 1 !important;

        .type-doc {
            font-size  : 10px;
            font-weight: 600;
            text-align : center;
        }
    }

    .type-doc {
        font-size  : 10px;
        font-weight: 600;
        text-align : center;
    }

    .emitente,
    .prestador,
    .passagem {
        font-size: 10px;

        .razao-social {
            font-weight: 800;
        }

        .companhia,
        .classe {
            display    : -ms-grid;
            display    : grid;
            font-weight: 400;

            span {
                font-weight: 800;
            }
        }

        .item {
            font-size  : smaller;
            font-weight: 300;
        }
    }

    .passagem {
        font-size: 10px;

        .item {
            font-size  : 10px;
            font-weight: 300;

            span {
                font-weight: 800;

            }
        }
    }



    .bpe {
        canvas {
            padding: 20px;
        }

        .chave-bpe {
            display: -ms-grid;
            display: grid;
            font-size: 8px;
        }
    }

}

@media print {
    .pagebreak {
        page-break-before: always;
    }

    /* page-break-after works, as well */
}